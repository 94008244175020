
import {Component, Vue} from "vue-property-decorator";
import api from "@/api/index";
import PagedTableView from "@/components/PagedTableView/index.vue";
import AbSelect from "@/components/AbSelect/index.vue";
import AgileAuditTag from "@/components/AgileAuditTag/index.vue";
import {InternalMonthlyPlanDto, AuditFlowScope} from "@/api/appService";

@Component({
  name: "internalMonthlyPlanList",
  components: {
    PagedTableView,
    AbSelect,
    AgileAuditTag
  },
  filters: {
    formatMonth(year: any, month: any) {
      if (month < 10) {
        month = "0" + month;
      }
      return year + "-" + month;
    }
  }
})
export default class internalMonthlyPlanList extends Vue {
  queryForm: any = {
    date: undefined,
    displayName: ""
  };

  created() {
    if (this.$route.query.date) {
      this.queryForm.date = this.$route.query.date;
    }
  }

  fetchData(params: any) {
    return api.internalMonthlyPlan.getAll(params);
  }

  // 操作按钮
  handleMenu($row: any) {
    switch ($row.type) {
      case "detail":
        this.handleDetail($row.item);
        break;

      case "edit":
        this.handleEdit($row.index, $row.item);
        break;

      case "delete":
        this.handleDelete($row.index, $row.item);
        break;

      case "startAudit":
        this.handleStartAudit($row.index, $row.item);
        break;

      case "auditFlow":
        this.handleAuditFlow($row.index, $row.item);
        break;
    }
  }

  handleCreate() {
    this.$router.push({
      name: "monthlyPlan-create"
    });
  }

  handleEdit(index: number, row: any) {
    this.$router.push({
      name: "monthlyPlan-edit",
      params: {
        id: row.id
      }
    });
  }

  handleDetail(row: any) {
    this.$router.push({
      name: "monthlyPlan-detail",
      params: {
        id: row.id
      }
    });
  }

  //获取审核状态
  getAuditStatus(index: number, row: any) {
    if ((row.audit != null && row.audit >= 0) && row.auditStatus === row.audit) {//已审核
      return true;
    } else if (row.audit === -1) {//被拒绝
      return false;
    } else if (row.audit == null) {//未开始
      return false;
    } else {//审核中
      return true;
    }
  }

  async handleStartAudit(index: number, row: InternalMonthlyPlanDto) {
    await api.internalMonthlyPlan
      .startAudit({body: {id: row.id}})
      .then(() => {
        this.$message.success("已提交");
      });
  }

  // 删除
  async handleDelete(index: number, row: InternalMonthlyPlanDto) {
    this.$confirm("你确定删除吗?", "提示").then(async () => {
      await api.internalMonthlyPlan.delete({id: row.id}).then(() => {
        this.$message({
          type: "success",
          message: "删除成功!"
        });
      });
    });
  }

  handleAuditFlow(index: number, row: any) {
    this.$router.push({
      name: "auditFlowDetail",
      params: {
        hostType: AuditFlowScope.InternalMonthlyPlan,
        hostId: row.id,
        auditFlowId: row.auditFlowId
      }
    });
  }
}
